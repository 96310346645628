
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ElUploadInternalFileDetail, ElUpload } from 'element-ui/types/upload'
@Component
export default class Import extends Vue {
  @Prop({ default: false }) private isShowImport!: boolean
  @Prop({ default: '' }) private importUrl!: string

  private fullscreenLoading = false;
  private isShowForm = true
  private headInfo = {
    token: window.sessionStorage.getItem('token') || '',
    customer: window.sessionStorage.getItem('customerCode') || ''
  }

  private fileList: ElUploadInternalFileDetail[] = []
  private resultList = []
  private resData = {
    success: 0,
    error: 0
  }

  private templateUrl = ''

  private ruleForm: {fileName: string | null} = {
    fileName: ''
  }

  created () {
    this.getTemplateUrl()
  }

  getTemplateUrl () {
    this.$axios.get(this.$apis.common.selectTemplate, { templateKey: 'manholeImportTemplate' }).then(res => {
      if (!res) {
        this.$message.error('请先配置导入模板')
      } else {
        this.templateUrl = res
      }
    })
  }

  // 文件上传之前
  beforeUpload (file: ElUploadInternalFileDetail) {
    const size = file.size / 1024 / 1024 < 10
    const type = 'xls,xlsx'
    const name = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
    if (type.indexOf(name) === -1) {
      this.$message({
        message: '上传文件只能是 .xls,.xlsx 格式!',
        type: 'warning'
      })
      return false
    } else if (!size) {
      this.$message({
        message: '上传文件大小不能超过 10MB!',
        type: 'warning'
      })
      return false
    } else {
      return true
    }
  }

  // 文件改变
  uploadChange (file: ElUploadInternalFileDetail) {
    this.fileList = []
    this.ruleForm.fileName = ''
    const beforeUpload = this.beforeUpload(file)
    if (beforeUpload) {
      this.fileList.push(file)
      this.ruleForm.fileName = file.name
    }
  }

  // 成功
  successFile (res: any) {
    this.fullscreenLoading = false
    this.isShowForm = false
    if (res.code !== 200) {
      this.$nextTick(() => {
        this.fileList = []
        this.ruleForm.fileName = ''
      })
      this.$message.error(res.message)
    } else {
      if (res.data.error === 0) {
        this.$emit('update:isShowImport', false)
        this.$message.success('已成功导入' + res.data.success + '条，失败0条')
        this.$emit('onSuccess')
      } else {
        this.resData = {
          error: res.data.error || 0,
          success: res.data.success || 0
        }
        this.$emit('onSuccess')
        this.resultList = res.data.list || []
      }
    }
  }

  // 保存
  submit () {
    if (this.fileList.length > 0) {
      this.fullscreenLoading = true;
      (this.$refs.upload as ElUpload).submit()
    } else {
      this.$message.warning('请选择文件')
    }
  }

  // 取消
  closeDialog () {
    this.isShowForm = true
    this.ruleForm.fileName = null
    this.$nextTick(() => {
      this.resultList = []
      this.resData = {
        success: 0,
        error: 0
      }
    })
    this.$emit('update:isShowImport', false)
  }
}
