
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '@/types/manholeManage'
import ImportData from './ImportData.vue'
@Component({
  name: 'manholeManage',
  components: { ImportData }
})
export default class extends Vue {
  private searchInfo = {
    projectId: '',
    manholeName: '',
    areaId: '',
    manholeType: ''
  }

  private tableData: TableData[] =[]
  private areaList = []
  private typeList = []

  private page = 1
  private total = 0
  private size = 10
  private loading=false
  private isShowImport = false
  private importUrl = ''

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
    this.getTypeList()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.manholeManage.selectManholeByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'manholeType'
    }).then(res => {
      this.typeList = res.manholeType || []
    })
  }

  getAreaList (projectId: string) {
    this.$axios.get(this.$apis.project.selectProjectAreaByList, {
      projectId,
      notAllArea: '1'
    }).then(res => {
      this.areaList = res.list || []
    })
  }

  projectChange (projectId: string) {
    this.searchInfo.areaId = ''
    this.areaList = []
    if (projectId) {
      this.getAreaList(projectId)
    }
  }

  onAdd () {
    this.$router.push({ name: 'manholeManageAdd' })
  }

  // 导入
  onImport () {
    this.importUrl = this.$apis.manholeManage.importManhole
    this.isShowImport = true
  }

  searchData () {
    this.page = 1
    this.getData()
  }

  onUpdate (id: string) {
    this.$router.push({ name: 'manholeManageUpdate', params: { id: id } })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.manholeManage.deleteManhole, { manholeId: id })
          .then(() => {
            this.$message.success('删除成功')
            this.searchData()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
